import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import axiosToken from "../../services/AxiosToken";
import router from "../../router";
import store from "../index"; // vuex store instance
import UtilJS from "../../assets/js/util";
Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    listaMensajes: [],
    cantidadMensajes : 0,
    mensajesNoLeidos: 0,
    listaMensajesError: "",
    cantidadMensajeNotificacion:"",
  },
  mutations: {
    SET_LISTAMENSAJES(state, dato) {
      state.listaMensajes = dato;
    },
    SET_LISTAMENSAJESERROR(state, dato) {
      state.listaMensajesError = dato;
    },
    SET_MENSAJESNOLEIDOS(state, dato) {
      state.mensajesNoLeidos = dato;
    },
    SET_CANTIDADMENSAJES(state, dato) {
      state.cantidadMensajes = dato;
    },
    SET_CANTIDADMENSAJENOTIFICACION(state, dato) {
      state.cantidadMensajeNotificacion = dato;
    },
  },
  actions: {
    // getMensajesByUsuario({ dispatch, commit }) {
    //   store.commit("SET_BLOQUEADO", true);
    //   var qs = require("qs");
    //   var config = {
    //     headers: {
    //       "Content-Type": "application/json;charset=UTF-8",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   };
    //   axiosToken.get("api/findMensajesByUsuario/"+this.state.user.user.id).then(function (response) {
    //       store.commit("mensajes/SET_LISTAMENSAJES", response.data);
    //       store.commit("mensajes/SET_CANTIDADMENSAJES", response.data.length);
    //       store.commit("mensajes/SET_LISTAMENSAJESERROR", null);
    //       store.commit("SET_BLOQUEADO", false);
    //     })
    //     .catch(function(error) {
    //       store.commit("SET_BLOQUEADO", false);
    //       store.commit("mensajes/SET_LISTAMENSAJES", null);
    //       store.commit(
    //         "mensajes/SET_LISTAMENSAJESERROR",
    //         "No se puedieron recuperar los mensajes."
    //       );
    //     });
    // },
    async getMensajesByUsuario({ dispatch, commit },pag) {
      store.commit("SET_BLOQUEADO", true);
      var qs = require("qs");
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      
      return axiosToken.get("api/findNotificacionesByUser/"+this.state.user.user.email+"/"+this.state.user.user.empresa.cuit+"/"+pag).then(function (response) {

          store.commit("mensajes/SET_LISTAMENSAJES", response.data);
          store.commit("mensajes/SET_MENSAJESNOLEIDOS", response.data.notificacionesNoLeidas);
          store.commit("mensajes/SET_CANTIDADMENSAJES", response.data.notificaciones.length);
          store.commit("mensajes/SET_LISTAMENSAJESERROR", null);
          store.commit("SET_BLOQUEADO", false);
          return response;
        })
        .catch(function(error) {
          store.commit("SET_BLOQUEADO", false);
          store.commit("mensajes/SET_LISTAMENSAJES", null);
          store.commit("mensajes/SET_MENSAJESNOLEIDOS", null);
          store.commit(
            "mensajes/SET_LISTAMENSAJESERROR",
            "No se puedieron recuperar los mensajes."
          );
          return error;
        });
    },
    getNotificacionMensaje({ dispatch, commit }) {
      var qs = require("qs");
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      
      axiosToken.get("api/getMensajeNotificacion/"+this.state.user.user.id,config).then(function (response) {
        store.commit("mensajes/SET_CANTIDADMENSAJENOTIFICACION", response.data);
        })
        .catch(function(error) {
          Vue.swal("Portal de Proveedoras/es", error.response.data.message, "info");
        });
    },
 
  },
  modules: {},
};
