//constate del S3

const VUE_APP_ACCESS_KEY_ID="AKIA3MHANFKIICA4V57Z";
const VUE_APP_SECRET_ACCESS_KEY="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";
const VUE_APP_REGION="sa-east-1";
const VUE_APP_BUCKET="provart-portalproveedores-prod";

//constantes link landing

const VUE_APP_LINK_REGISTRO="https://www.provinciart.com.ar/modules/proveedores/registracion";
const VUE_APP_LINK_FACTURA="https://www.provinciart.com.ar/modules/proveedores/plataforma";
const VUE_APP_LINK_COTIZACION="https://www.youtube.com/watch?v=wsJ9BAWWaWE&list=PL1q71_d_0K_4ysLAfhVJ1M8qH5I8iQJ3T&index=2"

export default {
    VUE_APP_ACCESS_KEY_ID:VUE_APP_ACCESS_KEY_ID,
    VUE_APP_SECRET_ACCESS_KEY:VUE_APP_SECRET_ACCESS_KEY,
    VUE_APP_REGION:VUE_APP_REGION,
    VUE_APP_BUCKET:VUE_APP_BUCKET,
    VUE_APP_LINK_REGISTRO:VUE_APP_LINK_REGISTRO,
    VUE_APP_LINK_FACTURA:VUE_APP_LINK_FACTURA,
    VUE_APP_LINK_COTIZACION:VUE_APP_LINK_COTIZACION,
}
