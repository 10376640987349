import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from '../../router';
import store from '../index'; // vuex store instance
import axiosToken from "../../services/AxiosToken";
Vue.use(Vuex)

export default ({
    namespaced: true,
    state: {
        tramiteSeleccionado: "",
        tramiteSeleccionadoDesc: "",
        tramiteSeleccionadoId: "",
        tramitesIngresados: [],
        errorListaTramite: "",
        tramites:[],
        tramites_legajo:[],
        tramiteEstados:[],
        activo: "1",
        idTramiteGenerico:"",
        iconTramite:"",
        atributoOrigen:"",
        atributoDestino:"",
        tramiteDestino:"",
        filesByTramite:[],
        dataByTramite:[],
    },
    mutations: {
        SET_ATRIBUTOORIGEN(state, dato) {
            state.atributoOrigen = dato;
        },
        SET_ATRIBUTODESTINO(state, dato) {
            state.atributoDestino = dato;
        },
        SET_TRAMITEDESTINO(state, dato) {
            state.tramiteDestino = dato;
        },
        SET_ICONTRAMITE(state, dato) {
            state.iconTramite = dato;
        },
        SET_TRAMITESELECCIONADO(state, dato) {
            state.tramiteSeleccionado = dato;
        },
        SET_TRAMITESELECCIONADODESC(state, dato) {
            state.tramiteSeleccionadoDesc = dato;
        },
        SET_TRAMITESELECCIONADOID(state, dato) {
            state.tramiteSeleccionadoId = dato;
        },
        SET_LISTRAMITESINGRESADOS(state, dato) {
            state.tramitesIngresados = dato;
        },
        SET_ERRORLISTATRAMITE(state, dato) {
            state.errorListaTramite = dato;
        },
        SET_TRAMITES(state, dato) {
            state.tramites = dato;
        },
        SET_TRAMITES_LEGAJO(state, dato) {
            state.tramites_legajo = dato;
        },
        SET_TRAMITE_ESTADO(state, dato) {
            state.tramiteEstados = dato;
        },
        SET_ACTIVO(state, dato) {
            state.activo = dato;
        },
        SET_ID_TRAMITE_GENERICO_ID(state, dato) {
            state.idTramiteGenerico = dato;
        },
        SET_FILE_BY_TRAMITE_ID(state, dato) {
            state.filesByTramite = dato;
        },
        SET_DATA_BY_TRAMITE_ID(state, dato) {
            state.dataByTramite = dato;
        },
    },
    actions: {
        getRegistros({ dispatch, commit }) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: { 'Content-Type': 'application/json;charset=UTF-8', 'Access-Control-Allow-Origin': '*' },
            };
                axiosToken.get("api/findTramitesByUsuarioByEmpresa/" + this.state.user.user.id + "/" + this.state.user.user.empresa.id).then(function (response) {
                store.commit('tramiteGenerico/SET_LISTRAMITESINGRESADOS', response.data);
                store.commit('tramiteGenerico/SET_ERRORLISTATRAMITE', null);
                store.commit('SET_BLOQUEADO', false);
            })
            .catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                store.commit('tramiteGenerico/SET_LISTRAMITESINGRESADOS', null);
                store.commit('tramiteGenerico/SET_ERRORLISTATRAMITE', "No se encontraron registros para el usuario ingresado.");
            })
        },
        getTramites({ dispatch, commit },data) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: { 'Content-Type': 'application/json;charset=UTF-8', 'Access-Control-Allow-Origin': '*' },
            };
            axiosToken.get("api/tramites/"+data).then(function (response) {
                store.commit('tramiteGenerico/SET_TRAMITES', response.data);
                store.commit('SET_BLOQUEADO', false);
            })
            .catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                store.commit('tramiteGenerico/SET_TRAMITES', null);
                Vue.swal({
                    title: "Proveedoras/es",
                    text: error.response.data,
                    icon: "info"
                }).then(function() {
                    window.location = "/home";
                })
            })
        },
        getTramitesLegajo({ dispatch, commit },data) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                headers: { 'Content-Type': 'application/json;charset=UTF-8', 'Access-Control-Allow-Origin': '*' },
            };
            axiosToken.get("api/tramites/"+data).then(function (response) {
                store.commit('tramiteGenerico/SET_TRAMITES_LEGAJO', response.data);
                store.commit('SET_BLOQUEADO', false);
            })
            .catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                store.commit('tramiteGenerico/SET_TRAMITES_LEGAJO', null);
                Vue.swal({
                    title: "Proveedoras/es",
                    text: error.response.data,
                    icon: "info"
                }).then(function() {
                    window.location = "/home";
                })
            })
        },
        getTramiteEstados({ dispatch, commit },parameters) {
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var data = JSON.stringify(parameters);
            var config = {
                method: 'post',
                url: '/api/findInfoTramiteGenerico',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
                
                store.commit('tramiteGenerico/SET_TRAMITE_ESTADO', response.data);
                store.commit("tramiteGenerico/SET_ID_TRAMITE_GENERICO_ID", response.data.tramite_id);
                store.commit('SET_BLOQUEADO', false);
                window.location.assign(parameters.url)
            }).catch(function (error) {
                

                store.commit('SET_BLOQUEADO', false);
                Vue.swal("Portal Proveedores",error.response.data.mensaje,"info");
            })

        },
        sendTramite({ dispatch, commit }, parameters) {
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
            var qs = require('qs');
            var data = JSON.stringify(parameters);
            var config = {
                method: 'post',
                url: '/api/findInfoTramiteGenerico',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },
        sendCalendar({ dispatch, commit }, dataIn) {

            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
            var qs = require('qs');
            
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/saveRangoHorario',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },




        sendTramiteGenerico({ dispatch, commit }, dataIn) {
            
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
            var qs = require('qs');
            var data = JSON.stringify(dataIn);
            var config = {
                method: 'post',
                url: '/api/guardarTramiteGenerico',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },
        sendArchivoMultuple({ dispatch, commit }, parameters) {
            store.commit('SET_BLOQUEADO', true);
            
            return new Promise((resolve, reject) => {
            var qs = require('qs');
            var data = JSON.stringify(parameters);
            var config = {
                method: 'post',
                url: '/api/findInfoTramiteGenerico',
                data: data,
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },
        sendDataFile({ dispatch, commit }, dataIn) {
            var qs = require('qs');
            var config = {
                method: 'post',
                url: '/api/infoArchivoMultiple',
                data: JSON.stringify(dataIn),
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
            }).catch(e => {
                store.commit("SET_BLOQUEADO", false);
                Vue.swal("Portal Proveedores", JSON.stringify(e), "error");
            });
        },
        removeFile({ dispatch, commit }, dataIn) {
            
            let obj = new Object();
            obj.dataKey=dataIn;
            store.commit('SET_BLOQUEADO', true);
            var qs = require('qs');
            var config = {
                method: 'post',
                url: '/api/bajaArchivoMultiple',
                data: JSON.stringify(obj),
                headers: {
                    'content-type': 'application/json;charset=utf-8',
                }
            };
            axiosToken(config).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
            }).catch(e => {
                store.commit("SET_BLOQUEADO", false);
                Vue.swal("Portal Proveedores", JSON.stringify(e), "error");
            });
        },

        getDeftTramiteByCode({ dispatch, commit }, code) {
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
            var qs = require('qs');
            axiosToken.get("api/getDeftTramite/"+ code).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },

        getFileByTramiteID({ dispatch, commit }, idtramite) {
            
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
            var qs = require('qs');
            axiosToken.get("api/files/getFiles/"+ idtramite).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },
        getDetalleByTramiteID({ dispatch, commit }, idtramite) {
            store.commit('SET_BLOQUEADO', true);
            return new Promise((resolve, reject) => {
            var qs = require('qs');
            axiosToken.get("api/getEstadosTramiteById/"+ idtramite).then(function (response) {
              store.commit('SET_BLOQUEADO', false);
              resolve(response.data)
            }).catch(function (error) {
                store.commit('SET_BLOQUEADO', false);
                reject(error)
            })
          })
        },

        
    },
    modules: {}
})